import { css } from "@emotion/core"
import styled from "@emotion/styled"

import Img from "gatsby-image"

 const Container = styled.section`
  ${({ theme }) => css`
    background: #ffffff;

    padding: 50px 0;

    .content {
      h2 {
        color: ${theme.colors.blue};

        font-size: 40px;
        line-height: 60px;
        margin-bottom: 20px;
      }

      p {
        color: ${theme.colors.blue};
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 20px;
      }

      span {
        color: #8b8b8b;
        font-size: 12px;
        line-height: 18px;
      }
    }

    ${theme.queries.small} {
      text-align: justify;
    }

    ${theme.queries.large} {
      padding: 106px 0 50px;

      .content {
        flex: 1;
        margin-right: 50px;

        h2 {
          font-size: 56px;
        }

        p {
          font-size: 23px;
          line-height: 32px;
          margin-bottom: 32px;
        }

        span {
          font-size: 16px;
          line-height: 21px;
        }
      }
    }
  `}
`

 const Wrapper = styled.div`
  ${({ theme }) => css`
    max-width: 90%;
    width: 1200px;

    display: flex;
    justify-content: space-between;
    margin: 0 auto 100px;
  `}
`

 const Image = styled(Img)`
  ${({ theme }) => css`
    width: 100%;

    ${theme.queries.small} {
      margin-top: 30px;
    }

    ${theme.queries.large} {
      width: 500px;
      height: 100%;
    }
  `}
`

const SubTitle = styled.h5`
    font-size: 26px;
    color: #00a1ff;
    font-weight: 300;
    margin-bottom: 15px;
  `

const Key = styled.h5`
    font-weight: bold;
    font-size: 26px;
    line-height: 47px;
    color: #203760;

    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
      cursor: pointer;
    }
  `

const PixButton = styled.button`
    border: 0;
    cursor: pointer;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 19px;
    color: #ffffff !important;
    text-transform: uppercase;

    padding: 20px 45px;
    background: #00a1ff;
    box-shadow: 0px 14px 24px rgba(0, 161, 255, 0.2);
    border-radius: 10px;
  `

const Tag = styled.strong`
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 12px !important;
    line-height: 14px;
    color: #94A6C5 !important;
  `

export {Key,PixButton,SubTitle,Container,Wrapper,Tag,Image}
