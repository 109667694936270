import styled from "@emotion/styled";
import {css} from "@emotion/core";
import {motion} from "framer-motion";
import Container from "../../../Container";

const Wrapper = styled.div`
  ${({theme}) => css`
    padding: 30px 0 120px;
    
    ${theme.queries.small} {
      padding: 10px 0 64px;
    }

    ${theme.queries.short} {
      padding-top: 5px;
    }
  `}
`

const Button = styled(motion.button)`
  ${({theme, uppercase, icon, blocked}) => css`
    background-color: #ffffff;
    box-shadow: 0px 64px 100px rgba(32, 54, 96, 0.2);
    border-radius: 10px;
    height: 210px;
    padding: 40px 100px 40px 60px;
    text-align: left;
    background-image: url("data:image/svg+xml;utf8,${icon}");
    background-repeat: no-repeat;

    float: left;
    position: relative;

    display: inline-flex;
    align-items: flex-end;

    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 30px;
    ${blocked ? `color: rgba(32, 55, 96, 0.2);` : `color: #203760;`}

    border: none;
    cursor: pointer;

    ${theme.queries.short} {
      height: 170px;
      padding: 20px 50px;
      font-size: 20px;

      svg {
        top: 30px;
      }
    }
    
    &#button-donate-pix{
      border: 3px solid ${theme.colors.yellowRed};
      background-color: ${theme.colors.blue};
      color: white;
      
      .icon-pix{
        fill: white;
        stroke: white;
      }
      
      .start-pix{
        position: absolute;
        top: 20px;
        right: 20px
      }
      
      .spotlight-pix{
        width: auto!important;
        position: absolute;
        top:0;
        right:20px;
        background-color: ${theme.colors.yellowRed};
        font-size: 13px;
        line-height: 1;
        padding: 8px 15px;
        border-radius: 20px;
        transform: translateY(-50%);
      }
    }

    ${uppercase && css`
        text-transform: uppercase;
   `}
`}
`

const Hi = styled.p`
  ${({theme}) => css`
    font-weight: bold;
    font-size: 23px;
    line-height: 27px;
    color: ${theme.colors.red};
    margin-bottom: 12px;

    ${theme.queries.small} {
      font-size: 16px;
    }
  `}
`

const Title = styled.h1`
  ${({theme}) => css`
    font-size: 60px;
    line-height: 106.2%;
    color: #203760;
    margin-bottom: 12px;
    text-align: center;
    width: 100%;

    ${theme.queries.small} {
      font-size: 30px;
      line-height: 30px;
    }

    ${theme.queries.short} {
      font-size: 48px;
    }
  `}
`

const Description = styled.p`
  ${({theme}) => css`
    color: ${theme.colors.mediumGrey};
    font-size: 20px;
    margin-bottom: 43px;
    max-width: 503px;

    ${theme.queries.small} {
      font-size: 14px;
      line-height: 16px;
    }
  `}
`

const CustomContainer = styled(Container)`
  ${({theme}) => css`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(360px,1fr));
    gap: 30px;
    

    ${theme.queries.small} {
      div {
        width: 100%;
        margin: 0;
      }
    }
  `}
`

const ImageContainer = styled.div`
  width: 100%;
  max-width: 484px;
  position: relative;
  height: 100%;

  ${({theme}) => css`
    ${theme.queries.small} {
      padding: 20px;
      margin: 0 auto !important;
    }
  `}
`

const mobileButtonStyles = ({queries}) => css`
  ${queries.small} {
    font-size: 14px;
  }
`

const defaultFontStyles = () => css`
  font-family: "Poppins", Arial, Sans-serif !important;
  font-weight: bold;
`

export {
    Wrapper,
    Button,
    Title,
    Hi,
    Description,
    ImageContainer,
    CustomContainer,
    defaultFontStyles,
    mobileButtonStyles,
}