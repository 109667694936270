/** @jsx jsx */
import { jsx } from "@emotion/core"
import React,{ useState, useEffect } from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"

import DonateNow from "../components/pages/Home/DonateNow"
import Pix from "../components/pages/Home/Pix"
import DonationPower from "../components/pages/Home/DonationPower"
import MaterialsAndFood from "../components/pages/Home/MaterialsAndFood"
import WhatsApp from "../components/pages/Home/WhatsApp"

import isMobile from "../utils/isMobile"

const IndexPage = ({ pageContext: translation}) => {
  const [mobile, setMobile] = useState(false)

  useEffect(() => {
    setMobile(isMobile())
  }, [])

  return (
    <Layout translation={translation}>
      <SEO
        lang={translation.currentLang}
        title={translation.pageName}
        description="Site de doações"
      />

      <DonateNow translation={translation} />

      {!mobile && (
        <>
          {translation.currentLang === "pt" && (
            <Pix translation={translation} />
          )}
          <DonationPower translation={translation} />
          <MaterialsAndFood translation={translation} />
        </>
      )}

      <WhatsApp />
    </Layout>
  )
}

export default IndexPage
