/** @jsx jsx */
import { jsx } from "@emotion/core"

import { useStaticQuery, graphql } from "gatsby"

import { Container, Wrapper, Key,Tag,SubTitle,PixButton } from "./styles"

import Phone from "../../../../assets/images/svg/phone-pix.svg"
import KeyIcon from "../../../../assets/images/svg/key-icon.svg"
import Heart from "../../../../assets/images/svg/logo-heart.svg"

import DonateWithPix from "../../../../assets/images/svg/doe-com-pix.svg"
import CopyToClipboard from "../../../../assets/images/svg/copy-to-clipboard.svg"
import { langNavigate } from "../../../../helpers"

import copyToClipboard from "../../../../templates/home/CopyToClipboard";

const Pix = ({ translation }) => {
  const data = useStaticQuery(graphql`
    query {
      pix: file(relativePath: { eq: "pix-home.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
      <Container id="pix-section">
        <Wrapper style={{ position: "relative" }}>
          <div className="content">
            <DonateWithPix style={{ marginTop: "100px", marginBottom: "40px" }} />
            <div style={{ display: "flex", marginBottom: "54px" }}>
              <KeyIcon style={{ marginRight: "18px" }} />
              <div style={{ marginTop: "5px" }}>
                <SubTitle>Confira as nossas chaves:</SubTitle>

                <Key>
                  doar@universal.org
                  <CopyToClipboard
                    onClick={() => {
                      copyToClipboard("doar@universal.org")
                    }}
                  />
                </Key>

                <Key>
                  <strong style={{ display: "none"}}>cnpj pix universal 29.744.778/0001-97</strong>
                  <strong style={{ display: "none"}}>cnpj igreja universal pix 29.744.778/0001-97</strong>
                  29.744.778/0001-97
                  <CopyToClipboard
                    onClick={() => {
                      copyToClipboard("29.744.778/0001-97")
                    }}
                  />
                </Key>
                <div style={{ display: "flex", maxWidth: '400px'}}>
                  <Tag>Para realizar uma transferência para o CNPJ via PIX da Igreja Universal, utilize a chave CNPJ 29.744.778/0001-97.
                    Por meio do CNPJ da Igreja Universal, se tornou simples para transferir o seu PIX. </Tag>
                  <Heart style={{ marginLeft: "18px",marginRight: "-5px", width: '28px', height: '28px', minWidth: '28px'}}  />
                </div>
              </div>
            </div>

            <PixButton onClick={() => langNavigate(19, translation.currentLang)}>
              NÃO SABE COMO USAR O PIX? VEJA AQUI
            </PixButton>
          </div>
          <Phone style={{ position: "absolute", right: 0 }} />  
        </Wrapper>
      </Container>
  )
}

export default Pix
