import {toast} from "react-toastify";

const CopyToClipboard = async text => {
    let textArea = document.createElement("textarea")
    textArea.value = text

    textArea.style.position = "fixed"
    textArea.style.left = "-999999px"
    textArea.style.top = "-999999px"
    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()

    return new Promise((res, rej) => {
        document.execCommand("copy") ? res() : rej()
        textArea.remove()
    })
        .then(() => {
            toast.success(`${text} copiado!`)
        })
        .catch(err => {
            toast.error("Erro ao copiar: ", err)
        })
}

export default CopyToClipboard;