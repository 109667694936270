import { css } from "@emotion/core"
import styled from "@emotion/styled"

export const Container = styled.section`
  ${({ theme }) => css`
    padding: 40px 30px 100px;
    color: #203760;
    text-align: center;

    h2 {
      font-weight: bold;
      font-size: 36px;
      line-height: 40px;
      text-transform: uppercase;

      small {
        font-size: 18px;
        color: #00a1ff;
        display: block;
      }
    }

    p {
      margin-top: 36px;
      font-size: 14px;
      line-height: 21px;
    }

    div {
      display: flex;
      align-items: center;
      margin: 30px auto 0;
      width: fit-content;

      svg {
        margin-right: 30px;
      }

      strong {
        font-weight: bold;
        font-size: 30px;
        line-height: 45px;
      }
    }

    ${theme.queries.large} {
      h2 {
        font-size: 72px;
        line-height: 70px;

        small {
          font-size: 36px;
        }
      }

      p {
        font-size: 20px;
        line-height: 30px;
        max-width: 40rem;
        margin-left: auto;
        margin-right: auto;
      }

      div strong {
        font-size: 40px;
      }
    }
  `}
`

export const WhatsAppContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-size: 40px;
    font-weight: 700;
    line-height: 60px;
    margin: 0 auto 12px;
  }

  a {
    cursor: pointer;

    svg {
      margin: 0;
    }
  }
`
