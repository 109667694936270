import styled from "@emotion/styled";
import {css} from "@emotion/core";

const Wrapper = styled.div`
  ${({ theme }) => css`
    max-width: 90%;
    width: 1200px;
    margin: 0 auto;
    
    h2{
        color: #203760;
        display: block;
        margin: 0 auto 25px;
        font-weight: bold;
        font-size: 19px;
        line-height: 106.2%;
        text-transform: uppercase;
        width: 210px;
        text-align: center;
    }
    
    .listButtons{
        display: grid;
        grid-row: repeat(6, 1fr);
        row-gap: 20px;

        button {
          background: #ffffff;
          box-shadow: 0px 64px 100px rgba(32, 54, 96, 0.2);
          border-radius: 10px;
          padding: 28px 36px;

          border: 0;
          text-align: left;

          height: 100px;

          color: #203760;
          font-weight: 600;
          font-size: 16px;
          line-height: 18px;
          text-transform: uppercase;

          display: flex;
          align-items: center;

          svg {
            margin-right: 15px;
            width: 38px;
          }
        }
    }
    
    #button-donate-pix{
      position: relative;
      border: 3px solid ${theme.colors.yellowRed};
      background-color: ${theme.colors.blue};
      color: white;
      
      .icon-pix{
        fill: white;
        stroke: white;
      }
            
      .start-pix{
        position: absolute;
        top: 20px;
        right: 20px
      }
      
      .spotlight-pix{
        position: absolute;
        top:0;
        right:20px;
        background-color: ${theme.colors.yellowRed};
        font-size: 13px;
        line-height: 1;
        padding: 8px 15px;
        border-radius: 20px;
        transform: translateY(-50%);
      }
    }
  `}
`

export {
    Wrapper
}