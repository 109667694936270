/** @jsx jsx */
import { jsx, css } from "@emotion/core"

import { useDispatch } from "react-redux"

import CreditCardIcon from "../../../../assets/images/svg/credit-card-icon.svg"
import BankSlipIcon from "../../../../assets/images/svg/bank-slip-icon.svg"
import PixIconWhite from "../../../../assets/images/svg/pix-icon-withe.svg"
import TransferIcon from "../../../../assets/images/svg/transfer-icon.svg"
import MaterialsIcon from "../../../../assets/images/svg/materials.svg"

import { langNavigate } from "../../../../helpers"
import { setFormOfPayment } from "../../../../state/app"

import {Wrapper} from "./style-mobile"

const MobileVersion = ({ translation }) => {
  const dispatch = useDispatch()

  return (
    <section
      css={css`
        padding: 0 0 50px;
      `}
    >
      <Wrapper>
        <h2>
          Como deseja fazer a sua doação?
        </h2>

        <div className={`listButtons`}>
          <button
            onClick={() => {
              dispatch(setFormOfPayment("credit"))
              langNavigate(5, translation.currentLang)
            }}
          >
            <CreditCardIcon />
            Cartão de Crédito
          </button>
          <button
            onClick={() => {
              dispatch(setFormOfPayment("billet"))
              langNavigate(5, translation.currentLang)
            }}
          >
            <BankSlipIcon />
            Boleto Bancário
          </button>
          <button
              id={`button-donate-pix`}
              onClick={() => {
              dispatch(setFormOfPayment("pix"))
              langNavigate(5, translation.currentLang)
              }}
          >
            <PixIconWhite />
            <div className={`spotlight-pix`}>
              recomendado
            </div>
            PIX COPIA-E-COLA
          </button>
          <button onClick={() => langNavigate(14, translation.currentLang)}>
            <TransferIcon />
            Transferência Bancária
          </button>

          <button onClick={() => langNavigate(20, translation.currentLang)}>
            <MaterialsIcon />
            Materiais e alimentos
          </button>
        </div>
      </Wrapper>
    </section>
  )
}

export default MobileVersion
