/** @jsx jsx */
import { jsx } from "@emotion/core"

import { Container, WhatsAppContainer } from "./styles"

import WhatsApp from "../../../../assets/images/svg/ic-whatsapp-receipt.svg"

const Receipt = () => {
  return (
    <Container>
      {/* <h2>
        <small>Confirmação e</small> comprovante
      </h2>
      <p>
        Ao realizar a sua doação, sempre entregue o comprovante em um altar da
        Universal ou envie para o nosso número no Whatsapp
      </p>

      <WhatsAppContainer>
        <p>11 2392-6814</p>

        <a href="https://wa.me/551123923333">
          <WhatsApp />
        </a>
      </WhatsAppContainer> */}
    </Container>
  )
}

export default Receipt
