/** @jsx jsx */
import {jsx, css, keyframes} from "@emotion/core"
import styled from "@emotion/styled"

import {useStaticQuery, graphql} from "gatsby"

import React, {useState, useEffect} from "react"
import {useDispatch} from "react-redux"

import {
    Wrapper,
    Button,
    Title,
    CustomContainer,
    mobileButtonStyles,
} from "./style"

import CreditCardIcon from "../../../../assets/images/svg/credit-card-icon.svg"
import BankSlipIcon from "../../../../assets/images/svg/bank-slip-icon.svg"
import PixIconWhite from "../../../../assets/images/svg/pix-icon-withe.svg"
import MaterialsIcon from "../../../../assets/images/svg/materials.svg"
import TransferIcon from "../../../../assets/images/svg/transfer-icon.svg"
import BG from "../../../../assets/images/svg/background.svg"

import {langNavigate} from "../../../../helpers"
import MobileVersion from "./mobile"

import isMobile from "../../../../utils/isMobile"

import {setFormOfPayment} from "../../../../state/app"
import SeoPix from "../../../SeoPix";

const DonateNow = ({translation}) => {
    const dispatch = useDispatch()

    const [showArrow, setShowArrow] = useState(1)

    const scrollAction = e => {
        //get scroll position
        const el = document.querySelector("html")
        var topWindow = el.scrollTop * 8

        //get height of window
        var windowHeight = el.scrollHeight

        //set position as percentage of how far the user has scrolled
        var position = topWindow / windowHeight
        position = 1 - position

        //define arrow opacity as based on how far up the page the user has scrolled
        //no scrolling = 1, half-way up the page = 0
        setShowArrow(position)
    }

    useEffect(() => {
        window.addEventListener("scroll", scrollAction, false)
        return () => {
            window.removeEventListener("scroll", scrollAction, false)
        }
    }, [])

    const data = useStaticQuery(graphql`
    query {
      pt: file(relativePath: { eq: "donating.png" }) {
        childImageSharp {
          fluid(maxWidth: 484) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      en: file(relativePath: { eq: "donating-en.png" }) {
        childImageSharp {
          fluid(maxWidth: 484) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      es: file(relativePath: { eq: "donating-es.png" }) {
        childImageSharp {
          fluid(maxWidth: 484) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

    const mover = keyframes`
      0% {
        transform: translateY(0);
      }
      100% {
        transform: translateY(-5px);
      }
      `

    const Arrow = styled.span`
    ${({theme, active}) => css`
      width: 40px;
      height: 40px;
      border-radius: 20px;
      background: rgba(255, 255, 255, 0.5);
      border: 1px solid ${theme.colors.darkGrey};
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 5px;
      opacity: ${showArrow};
      position: fixed;
      left: 50%;
      bottom: 15px;
      transform: translateX(-50%);
      z-index: 3;
      animation: ${mover} 1s infinite alternate;

      span {
        border: solid ${theme.colors.darkGrey};
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 4px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
      }
    `}
  `

    const [mobile, setMobile] = useState(false)

    useEffect(() => {
        setMobile(isMobile())
    }, [])

    if (mobile) {
        return (
            <div>
                <SeoPix/>
                <MobileVersion translation={translation}/>
            </div>
        )
    }

    const scrollToElementId = elementId => {
        document.getElementById(elementId).scrollIntoView({behavior: "smooth"})
    }

    return (
        <Wrapper>
            <Title>{translation.home.text1}</Title>
            <CustomContainer>
                <Button
                    onClick={() => {
                        dispatch(setFormOfPayment("credit"))
                        langNavigate(5, translation.currentLang)
                    }}
                    css={mobileButtonStyles}
                    uppercase
                >
                    <CreditCardIcon
                        css={css`
                            position: absolute;
                            top: 40px;
                            left: 60px;
                          `}
                    />
                    Cartão <br/>
                    de crédito
                </Button>
                <Button
                    onClick={() => {
                        dispatch(setFormOfPayment("ticket"))
                        langNavigate(5, translation.currentLang)
                    }}
                    css={mobileButtonStyles}
                    uppercase
                >
                    <BankSlipIcon
                        css={css`
                            position: absolute;
                            top: 40px;
                            left: 60px;
                          `}
                    />
                    Boleto <br/>
                    Bancário
                </Button>
                <Button
                    onClick={() => {
                        dispatch(setFormOfPayment("pix"))
                        langNavigate(5, translation.currentLang)
                    }}
                    css={mobileButtonStyles}
                    id={`button-donate-pix`}
                    uppercase
                >
                    <PixIconWhite
                        css={css`
                            position: absolute;
                            top: 40px;
                            left: 60px;
                          `}
                    />
                    <div className={`spotlight-pix`}>
                        recomendado
                    </div>
                    PIX COPIA-E-COLA
                </Button>
                <Button
                    onClick={() => scrollToElementId("boleto-bancario")}
                    css={mobileButtonStyles}
                    uppercase
                >
                    <TransferIcon
                        css={css`
                            position: absolute;
                            top: 40px;
                            left: 60px;
                          `}
                    />
                    Transferência <br/>
                    Bancária
                </Button>

                <Button
                    onClick={() => scrollToElementId("materiais-alimentos")}
                    css={mobileButtonStyles}
                    uppercase
                >
                    <MaterialsIcon
                        css={css`
                            position: absolute;
                            top: 40px;
                            left: 60px;
                          `}
                    />
                    Materiais e <br/>
                    Alimentos
                </Button>

                <Arrow>
                    <span></span>
                </Arrow>
            </CustomContainer>
            <BG
                css={css`
                  position: absolute;
                  left: 0;
                  z-index: -1;
                `}
            />
        </Wrapper>
    )
}

export default DonateNow
